import React from "react";
import { NavLink, useParams } from "react-router-dom";
import "../Css/ClientLink.css"; // Make sure to create and import your CSS file

const ClientLink = () => {
  const { clientId } = useParams(); // Assuming you're using clientId to generate the link

  return (
    <div className="page-container">
      {" "}
      {/* Ensure this container takes full height */}
      <div className="client-link-container">
        <h2>Comparte Este Link Con Tus Clientes</h2>
        <p>Con este enlace podras capturar mejores reviews.</p>
        <NavLink
          to={`/arquitect/client/${clientId}`}
          end
          className="nav-link-button"
        >
          <span>Ir Al link</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ClientLink;
