import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Css/ClientEdit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
const ClientEdit = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [googleReviewLink, setGoogleReviewLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchClientData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/clients/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Could not fetch client data");
        }

        const data = await response.json();
        setClientName(data.name);
        setGoogleReviewLink(data.googleReviewLink);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/${clientId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            name: clientName,
            googleReviewLink: googleReviewLink,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update client data");
      }

      navigate(`/arquitect/${clientId}/clientDashboard`); // Redirect to client dashboard after update
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    <div className="spinner-container">
      <FontAwesomeIcon icon={faCircleNotch} pulse size="2xs" />
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message
  }

  return (
    <>
      <div className="edit-client-info">
        <form onSubmit={handleSubmit} className="edit-client-form">
          <label>
            Name:
            <input
              type="text"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
          </label>
          <label>
            Google Review Link:
            <input
              type="text"
              value={googleReviewLink}
              onChange={(e) => setGoogleReviewLink(e.target.value)}
            />
          </label>
          <button type="submit">Save Changes</button>
        </form>
      </div>
    </>
  );
};

export default ClientEdit;
