import React from "react";
import "./Css/App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ClientRatings from "./Screens/ClientRatings";
import ClientReview from "./Components/ClientReview"; // Component for customers to leave reviews
import ClientLogin from "./Screens/ClientLogin"; // Component for client login
import AdminDashboard from "./Screens/AdminDashboard"; // Admin Dashboard component
import ClientDashboard from "./Screens/ClientDashboard"; // Client Dashboard component
import DashboardHome from "./Screens/DashboardHome";
import ClientLink from "./Screens/ClientLink";
import ProtectedRoute from "./ProtectedRoute"; // Component for protected routes
import ClientEdit from "./Screens/ClientEdit";
import Newclient from "./Screens/NewClient";
import ClientList from "./Screens/ClientList";
import ThankYou from "./Screens/ThankYou";
import LandingPage from "./Components/Landingpage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* Public Routes */}
        <Route path="/arquitect/client/:clientId" element={<ClientReview />} />
        <Route path="/thank-you" element={<ThankYou />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/arquitect/:clientId/adminDashboard"
            element={<AdminDashboard />}
          />
          <Route
            path="/arquitect/:clientId/adminDashboard/newclient"
            element={<Newclient />}
          />
          <Route
            path="/arquitect/:clientId/adminDashboard/clientlist"
            element={<ClientList />}
          />

          <Route
            path="/arquitect/:clientId/clientDashboard"
            element={<ClientDashboard />}
          >
            <Route index element={<DashboardHome />} />
            <Route path="reviews" element={<ClientRatings />} />
            <Route path="edit" element={<ClientEdit />} />
            <Route path="link" element={<ClientLink />} />
          </Route>
        </Route>

        {/* Login Route */}
        <Route path="/arquitect/login" element={<ClientLogin />} />

        {/* Catch-all Route: Redirects to Login if no other routes are matched */}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
