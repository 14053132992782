import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCog,
  faRightFromBracket,
  faGlobe,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import "../Css/SidePanel.css";

const SidePanel = () => {
  const { clientId } = useParams();
  return (
    <nav className="side-panel">
      <div className="side-panel-header">
        <h1 className="side-panel-title">Riviu</h1>
        <img src="/White.png" alt="ARQUITC Logo" className="side-panel-logo" />
      </div>
      <NavLink
        to={`/arquitect/${clientId}/clientDashboard`}
        end
        activeClassName="active"
      >
        <FontAwesomeIcon icon={faGripVertical} />
        <span>Dashboard</span>
      </NavLink>
      <NavLink
        to={`/arquitect/${clientId}/clientDashboard/reviews`}
        end
        activeClassName="active"
      >
        <FontAwesomeIcon icon={faStar} />
        <span>Reviews</span>
      </NavLink>
      <NavLink
        to={`/arquitect/${clientId}/clientDashboard/edit`}
        end
        activeClassName="active"
      >
        <FontAwesomeIcon icon={faCog} />
        <span>Ajustes</span>
      </NavLink>
      <NavLink
        to={`/arquitect/${clientId}/clientDashboard/link`}
        end
        activeClassName="active"
      >
        <FontAwesomeIcon icon={faGlobe} />
        <span>Consigue Reviews</span>
      </NavLink>

      <NavLink to={"/arquitect/login"} end activeClassName="active">
        <FontAwesomeIcon icon={faRightFromBracket} />
        <span>Log Out</span>
      </NavLink>
      {/* Add other links as necessary */}
    </nav>
  );
};

export default SidePanel;
