import React from "react";
import "../Css/AdminDashboard.css";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const navigate = useNavigate();
  const clientId = localStorage.getItem("clientId"); // Retrieve the client ID from local storage

  // Function to navigate to the Add Client view
  const navigateToAddClient = () => {
    navigate(`/arquitect/${clientId}/adminDashboard/newclient`);
  };

  // Function to navigate to the Client List view
  const navigateToClientList = () => {
    navigate(`/arquitect/${clientId}/adminDashboard/clientlist`);
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <nav>
        <button onClick={navigateToAddClient}>Add Client</button>
        <button onClick={navigateToClientList}>Client List</button>
      </nav>
      {/* Other components or content can be added here as needed */}
    </div>
  );
}

export default AdminDashboard;
