import React from "react";
import { useNavigate } from "react-router-dom";
import "../Css/BackButton.css";

function BackButton() {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="backNavigate-button">
       ←
    </button>
  );
}

export default BackButton;
