import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/ClientLogin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function ClientLogin() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clients/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, password }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("clientId", data.clientId);
      localStorage.setItem("role", data.role);

      if (data.role === "admin") {
        navigate(`/arquitect/${data.clientId}/adminDashboard`);
      } else {
        navigate(`/arquitect/${data.clientId}/clientDashboard`);
      }
    } catch (error) {
      console.error("Login error:", error.message);
    }
  };

  return (
    <div className="client-login-container">
      <div className="client-login-inner-container">
        <div className="client-login-image"></div>

        <form onSubmit={handleSubmit} className="client-login-form">
          <div className="header-container">
            <h2>Login</h2>
          </div>
          <span>Ingresa tu Usuario y Contraseña</span>

          <div className="input-container">
            <input
              type="text"
              id="username"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="username" className={name ? "filled" : ""}>
              Usuario
            </label>
          </div>
          <div className="input-container">
            <input
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password" className={password ? "filled" : ""}>
              Contraseña
            </label>
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="toggle-password-visibility"
              aria-label="Toggle password visibility"
            >
              <FontAwesomeIcon
                icon={isPasswordVisible ? faEye : faEyeSlash}
                style={{ color: "#000000" }}
              />
              {/* Replace with an appropriate icon */}
            </button>
          </div>

          <button className="submit" type="submit">
            Log In
          </button>
        </form>
      </div>
    </div>
  );
}

export default ClientLogin;
