import React from "react";
import "./Css/Logo.css"; // Create and import a CSS file for styling

function Logo({ isModalOpen }) {
  return (
    <div className={`logo-container ${isModalOpen ? "hidden" : ""}`}>
      <img src={process.env.PUBLIC_URL + "/Arquitc.png"} alt="ARQUITC Logo" />
    </div>
  );
}

export default Logo;
