import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/ClientList.css";
import BackButton from "../Components/BackButton";

function ClientList() {
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();

  // Fetch clients from the backend on component mount
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/clients`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    };

    fetchClients();
  }, []);

  // Function to handle clicking on a client
  const handleClientClick = (clientId) => {
    // Navigate to a route with client details - you'll need to set up this route
    navigate(`/admin-dashboard/client/${clientId}`);
  };

  return (
    <>
      <BackButton />
      <div className="client-list">
        <h2>Client List</h2>
        <ul>
          {clients.map((client) => (
            <li key={client._id} onClick={() => handleClientClick(client._id)}>
              {client.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default ClientList;
