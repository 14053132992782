import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Css/LandingPage.module.css"; // Corrected path for CSS module
import logo from "../Images/Logo.png";
import illustration from "../Images/backgroundimage.png";
import Instagram from "../Images/Instagram.png";
import Rating from "../Images/rating1.png";
import Equipo from "../Images/Equipo.png";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/arquitect/login");
  };

  return (
    <div className={styles.App}>
      <header className={styles.header}>
        <div className={styles["logo-and-text"]}>
          <span className={styles["logo-text"]}>Riviu</span>
          <img
            src={logo}
            alt="Logotipo de Arquitc"
            className={styles["logo-image"]}
          />
        </div>
        <nav className={styles.navigation}>
          <a href="#como-funciona" className={styles["nav-link"]}>
            Cómo Funciona
          </a>
        </nav>
        <div className={styles["button-container"]}>
          {" "}
          {/* New container for buttons */}
          <button
            onClick={handleSignIn}
            className={styles["btn-get-started-main-up"]}
          >
            Iniciar Sesión
          </button>
        </div>
      </header>

      <section className={styles.hero}>
        <div className={styles["text-container"]}>
          <h1 className={styles["main-title"]}>
            Capturamos y Magnificamos las Experiencias Positivas de Tus Clientes
          </h1>
          <p className={styles.subtitle}>
            Herramientas digitales intuitivas diseñadas para maximizar tu
            presencia online y agilizar tus operaciones internas.
          </p>
          <a
            href="https://calendly.com/fguaragna97/arquitc-introduction"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={styles["btn-get-started-main"]}>
              Agenda Tu Demo
            </button>
          </a>
        </div>
        <div className={styles.illustration}>
          <img src={illustration} alt="Ilustración de gestión de reputación" />
        </div>
      </section>

      <main>
        <section className={styles["combined-section"]}>
          <div id="como-funciona" className={styles.section}>
            <p>
              Nuestras cajas de reseñas personalizadas combinan elegancia y
              funcionalidad, invitando a más interacciones y comentarios de los
              clientes para su negocio.
            </p>
            <img
              src={Instagram}
              className={styles.instagram}
              alt="Ilustración de gestión de reputación"
            />
          </div>

          <div id="caracteristicas" className={styles.section}>
            <p>
              Simplifica las reseñas de los clientes con nuestras soluciones
              digitales fáciles de usar, mejorando tu presencia en línea sin
              esfuerzo.
            </p>
            <img
              src={Rating}
              className={styles.special}
              alt="Ilustración de gestión de reputación"
            />
          </div>

          <div id="sobre-nosotros" className={styles.section}>
            <p>
              Aprovecha nuestros análisis avanzados para comprender las
              preferencias de los clientes y elevar la calidad de tu servicio
              basado en comentarios reales.
            </p>
            <img src={Equipo} alt="Ilustración de gestión de reputación" />
          </div>
        </section>
      </main>

      <footer className={styles.footer}>
        {/* Footer content can be added here */}
      </footer>
    </div>
  );
};

export default LandingPage;
