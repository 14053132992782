import React from "react";

function ThankYou() {
  return (
    <div className="App">
      <div className="centered-div">
        <h1>Gracias por tu review!</h1>
        <p>Usaremos tus comentarios para ser cada dia mejores.</p>
      </div>
    </div>
  );
}

export default ThankYou;
