import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReviewForm from "./ReviewForm";

function ClientReview({ setIsModalOpen }) {
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { clientId } = useParams();

  useEffect(() => {
    const fetchClientData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/clients/${clientId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setClientData(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching client data:", error);
        setError("Failed to load client data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="App">
      <div className="centered-div">
        <ReviewForm
          clientName={clientData.name}
          googleReviewLink={clientData.googleReviewLink}
          clientId={clientId}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </div>
  );
}

export default ClientReview;
