import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../Css/NewClient.css";
import BackButton from "../Components/BackButton";

function AdminPanel() {
  const [clientName, setClientName] = useState("");
  const [clientpassword, setClientPassword] = useState("");
  const [googleReviewLink, setGoogleReviewLink] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newClientData, setNewClientData] = useState({});
  const navigate = useNavigate(); // For programmatic navigation

  const handleClientSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/clients`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: clientName,
          password: clientpassword,
          googleReviewLink: googleReviewLink,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setNewClientData(data);
      setIsSubmitted(true); // Set submission success
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  if (isSubmitted) {
    // Success view after client creation
    const reviewPagePath = `/arquitc/client/${newClientData._id}`;
    return (
      <div className="success-view">
        <h2>{newClientData.name} Created Successfully!</h2>
        <p>
          Direct link for reviews:
          <a href={reviewPagePath}>{reviewPagePath}</a>
        </p>
        <button onClick={() => navigate(reviewPagePath)}>
          Go to Review Page
        </button>
      </div>
    );
  }

  // Form view
  return (
    <>
      <BackButton />
      <div className="admin-panel-container">
        <h2>Create New Client</h2>
        <form onSubmit={handleClientSubmit} className="admin-form">
          <div className="form-group">
            <label>
              Name of the Client:
              <input
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Client Name"
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Password
              <input
                type="text"
                value={clientpassword}
                onChange={(e) => setClientPassword(e.target.value)}
                placeholder="Client Password"
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Google Review Link:
              <input
                type="text"
                value={googleReviewLink}
                onChange={(e) => setGoogleReviewLink(e.target.value)}
                placeholder="Google Review Link"
              />
            </label>
          </div>
          <button type="submit">Create Client</button>
        </form>
      </div>
    </>
  );
}

export default AdminPanel;
