import React from "react";
import "../Css/ReviewModal.css";
import { useNavigate } from "react-router-dom"; // Make sure you have your CSS file for styling

function ReviewModal({
  isOpen,
  onClose,
  googleReviewLink,
  comment,
  onReviewed,
}) {
  let navigate = useNavigate();
  if (!isOpen) return null;

  const handleGoogleReview = () => {
    navigator.clipboard
      .writeText(comment)
      .then(() => {
        window.open(googleReviewLink, "_blank");
        onClose();
        onReviewed(); // Callback to parent to handle any post-review logic
        // This will close the modal and reset the comment
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        window.open(googleReviewLink, "_blank");
        onClose(); // Close the modal even if there's an error
        onReviewed(); // Callback to parent to handle any post-review logic
      })
      .finally(() => {
        navigate("/thank-you"); // Navigate to the thank you page
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Gracias por tu review!</h2>
        <p>Nos ayudas compartiendola en google?</p>
        <p>Hemos copiado el comentario por ti solo es darle pegar</p>
        {/* Add type="button" to prevent the default submit action */}
        <button type="button" onClick={handleGoogleReview}>
          Claro que si
        </button>
      </div>
    </div>
  );
}

export default ReviewModal;
