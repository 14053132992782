import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Css/DashboardHome.css";
import graph from "../Images/graph.png";
import rating from "../Images/rating.png";
import reviews from "../Images/reviews.png";
const DashboardHome = () => {
  const { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]); // Initialize as an empty array
  const [totalReviews, setTotalReviews] = useState(0); // Initialize as 0
  const [averageRating, setAverageRating] = useState(0); // Initialize as 0
  const [reviewsSentToGoogle, setReviewsSentToGoogle] = useState(0); // This will eventually be dynamic data

  // Move processDataForGraph function outside of useEffect if it doesn't depend on props or state

  useEffect(() => {
    const fetchClientData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/reviews/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Could not fetch client summary");
        }

        const reviews = await response.json();
        setIsLoading(false);
        setData(reviews); // Store the reviews data

        // Calculate the total number of reviews
        setTotalReviews(reviews.length);

        // Calculate the average rating
        const totalRating = reviews.reduce(
          (acc, review) => acc + review.rating,
          0
        );
        setAverageRating((totalRating / reviews.length).toFixed(1)); // Set average rating

        // Calculate the number of 5-star reviews
        const fiveStarReviewsCount = reviews.filter(
          (review) => review.rating === 5
        ).length;

        setReviewsSentToGoogle(fiveStarReviewsCount);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchClientData();
  }, [clientId]);

  // Display spinner while loading
  if (isLoading) {
    return (
      <div className="spinner-container">
        <FontAwesomeIcon icon={faCircleNotch} pulse size="2xs" />
      </div>
    );
  }

  // Function to process data for the graph
  //const processedData = processDataForGraph(data);

  return (
    <div className="dashboard-home">
      <div className="top-section">
        <div className="large-rectangle">
          <h3>Reviews Totales</h3>
          <p>{totalReviews}</p>
          <img src={graph} alt="graph" />
          {/* Content for the large rectangle */}
        </div>
        <div className="side-square">
          <h3>Promedio</h3>
          <p>{averageRating}</p>
          <img src={rating} alt="rating" />
          {/* Content for the square on the side */}
        </div>
      </div>
      <div className="bottom-section">
        <div className="bottom-right">
          <h3>Reviews Enviadas A Google</h3>
          <p>{reviewsSentToGoogle}</p>
          <img src={reviews} alt="reviews" />
        </div>
      </div>
    </div>
  );
};
export default DashboardHome;

const processDataForGraph = (reviews) => {
  // Initialize graph data with zero reviews for each month
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const graphData = months.map((month) => ({ time: month, reviewCount: 0 }));

  // Process the reviews
  reviews.forEach((review) => {
    const date = new Date(review.createdAt);
    const monthIndex = date.getMonth(); // 0 for Jan, 1 for Feb, etc.
    graphData[monthIndex].reviewCount++; // Increment the review count for the corresponding month
  });

  return graphData;
};
