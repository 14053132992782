import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Css/ClientRatings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment"; // You'll need to install moment for date formatting

const ClientRatings = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const { clientId } = useParams();

  // Function to calculate time since review
  const timeSince = (date) => moment(date).fromNow();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/reviews/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Could not fetch reviews");
        }
        const data = await response.json();
        setReviews(data);

        // Calculate average rating
        const totalRating = data.reduce(
          (acc, review) => acc + review.rating,
          0
        );
        setAverageRating((totalRating / data.length).toFixed(1));
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, [clientId]);

  return (
    <div className="client-ratings-page">
      <div className="summary-container">
        <div className="average-rating-container">
          <h2>Average Rating</h2>
          <p className="average-rating">{averageRating}</p>
          <div className="star-container">
            {[...Array(5)].map((star, index) => (
              <span
                key={index}
                className={index < averageRating ? "filled" : "unfilled"}
              >
                ★
              </span>
            ))}
          </div>
          <p className="total-reviews">{reviews.length} reviews</p>
        </div>
      </div>
      <div className="reviews-list-container">
        <h2>Client Reviews</h2>
        {reviews.length > 0 ? (
          [...reviews].reverse().map((review) => (
            <div key={review._id} className="review-card">
              <div className="review-header">
                <p className="review-time">{timeSince(review.createdAt)}</p>
                <div className="star-ratings">
                  {[...Array(5)].map((_, i) => (
                    <span
                      key={i}
                      className={`star ${
                        i < review.rating ? "filled" : "unfilled"
                      }`}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <p className="review-comment">{review.comment}</p>
            </div>
          ))
        ) : (
          <FontAwesomeIcon
            icon={faCircleNotch}
            pulse
            size="2xs"
            className="spinner"
          />
        )}
      </div>
    </div>
  );
};

export default ClientRatings;
