import React from "react";
import { useParams, Outlet } from "react-router-dom";
import "../Css/ClientDashboard.css";
import SidePanel from "../Components/SidePanel";

const ClientDashboard = () => {
  const { clientId } = useParams();

  return (
    <div className="client-dashboard-container">
      <SidePanel clientId={clientId} />
      <div className="dashboard-content">
        <Outlet />
      </div>
    </div>
  );
};

export default ClientDashboard;
