import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReviewModal from "./ReviewModal";
import "../Css/ReviewForm.css";
import Logo from "../Logo";
import Confetti from "react-confetti";

function ReviewForm({ clientName, googleReviewLink, clientId }) {
  // clientName passed as a prop
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiWidth, setConfettiWidth] = useState(window.innerWidth);
  const [confettiHeight, setConfettiHeight] = useState(window.innerHeight);

  let navigate = useNavigate();
  useEffect(() => {
    // Automatically stop confetti if user deselects 5 stars
    if (rating < 5) {
      setShowConfetti(false);
    }
  }, [rating]);

  useEffect(() => {
    const handleResize = () => {
      setConfettiWidth(window.innerWidth);
      setConfettiHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (event) => {
    const reviewData = {
      rating: rating,
      comment: comment,
    };

    console.log(reviewData);

    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reviews`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          client: clientId, // Replace with actual client ID
          comment: comment, // Assuming 'comment' is your state for the comment text
          rating: rating, // Assuming 'rating' is your state for the rating value
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      // Handle success - maybe clear the form or show a success message
    } catch (error) {
      console.error("Error submitting review:", error);
      // Handle errors - show error message to the user
    }

    if (rating === 5) {
      setModalOpen(true);

      return;
      // Do not reset the comment here
    } else if (rating <= 4) {
      navigate("/thank-you");
      setModalOpen(false);

      setComment(""); // Reset comment here for non-5-star ratings
    }

    // Only reset rating and hover, not comment
    setRating(0);
    setHover(0);
  };

  const handleStarClick = (index) => {
    setRating(index);
    if (index === 5) {
      setShowConfetti(true);
    } else {
      setShowConfetti(false);
    }
  };
  const onReviewed = () => {
    // Handle post-review actions, like resetting state
    setComment(""); // Reset the comment
  };

  function getImageSrc(rating) {
    if (rating >= 1 && rating <= 2) {
      return "/Ex13.png"; // Image for 1 and 2 stars
    } else if (rating >= 3 && rating <= 4) {
      return "/Ex4.png"; // Image for 3 and 4 stars
    } else if (rating === 5) {
      return "/Ex1.png"; // Image for 5 stars
    } else {
      return "/Ex0.png"; // Default image when no stars are clicked
    }
  }

  return (
    <div className="ReviewFormContainer">
      {showConfetti && (
        <Confetti width={confettiWidth} height={confettiHeight} />
      )}
      <form onSubmit={handleSubmit}>
        <div className="image-container">
          <img src={getImageSrc(rating)} alt="Rate Your Experience" />
        </div>
        <h1>{clientName}</h1> {/* Use clientName from props */}
        <div className="star-rating">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <span
                key={index}
                className={index <= (hover || rating) ? "on" : "off"}
                onClick={() => handleStarClick(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
              >
                ★
              </span>
            );
          })}
        </div>
        <textarea
          placeholder="Cuentanos tu experiencia..."
          rows="4"
          cols="50"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <br />
        <button type="submit">ENVIAR</button>
        <ReviewModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setComment(""); // Reset comment here
          }}
          googleReviewLink={googleReviewLink}
          comment={comment}
          onReviewed={onReviewed}
          // New prop to handle comment reset
        />
      </form>
      <Logo></Logo>
    </div>
  );
}

export default ReviewForm;
